<template>
  <el-row style="margin-top: 200px">
    <el-col :span="6" :offset="9">
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>悦普研究院数据平台</span>
        </div>
        <el-form ref="form" :model="form" label-width="80px">
          <el-form-item label="用户名">
            <el-input v-model="form.username"></el-input>
          </el-form-item>
          <el-form-item label="密码">
            <el-input show-password v-model="form.password"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit">登录</el-button>
          </el-form-item>
          <el-form-item>
            <el-link type="primary" @click="onRegister">注册</el-link>
          </el-form-item>
        </el-form>
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      form: {
        username: '',
        password: ''
      }
    }
  },
  props: {},
  methods: {
    onSubmit() {
      this.axios.post('/api/user/login', {
        username: this.form.username,
        password: this.form.password
      }).then(response => {
        localStorage.name = response.name;
        localStorage.role = response.role;
        localStorage.token = response.token;
        localStorage.vip = response.vip;
        this.$router.push('/home/overall');
      })
    },
    onRegister() {
      this.$router.push('/register');
    }
  }
}
</script>

<style scoped>

</style>